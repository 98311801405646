import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FileBrowser from "../components/Files/FileBrowser";
import FileUploadPopup from "../components/Popups/FileUploadPopup";
import FileViewer from "../components/Files/FileViewer";
import AddCollectionPopup from "../components/Popups/AddCollectionPopup";
import AddCollectionButton from "../components/Files/AddCollectionButton";
import { Grid, LinearProgress } from "@material-ui/core";
import { trackPage } from "../analytics";
import { Helmet } from "react-helmet";
import {
  setPageTitle,
  getFiles,
  addFile,
  addFolder,
  uploadFile,
  updateFile,
  updateFiles,
  deleteFiles,
  uploadMulti,
  getTags,
  getTagHighlights,
} from "redux/actions";
import { getFileFromTree } from "utils/utils";

const mapStateToProps = (state) => {
  return {
    files: state.files,
    filesUsage: state.filesUsage,
    loading: state.loading.MyFiles,
    fileUploadStatus: state.fileUploadStatus,
    fileUploadProgress: state.fileUploadProgress,
    updateSaving: state.loading["ConversationEditor"],
  };
};

const mapDispatchToProps = {
  setPageTitle,
  getFiles,
  addFile,
  addFolder,
  uploadFile,
  updateFile,
  updateFiles,
  deleteFiles,
  uploadMulti,
  getTags,
  getTagHighlights,
};

class Files extends Component {
  state = {
    fileUploadPopupOpen: false,
    collectionPopupOpen: false,
    highlightsPopupOpen: false,
    showTags: false,
  };

  componentDidMount() {
    trackPage("Files");
    this.props.getFiles();
    this.props.setPageTitle();
  }

  componentDidUpdate(prevProps) {
    for (var filePath in this.props.fileUploadStatus) {
      if (
        this.state.selectedFile &&
        filePath === this.state.selectedFile.path &&
        this.state.selectedFile.type === "FILE"
      ) {
        var file = getFileFromTree(prevProps.files, filePath);
        var newFile = getFileFromTree(this.props.files, filePath);
        if (newFile.status !== file.status) {
          this.setState({ selectedFile: newFile });
        }
      }
    }
  }

  openNewCollectionPopup = () => {
    this.setState({ collectionPopupOpen: true, selectedFile: null });
  };
  closeCollectionPopup = () => {
    this.setState({ collectionPopupOpen: false });
  };
  handleCollectionSubmit = (collectionName) => {
    this.props.addFile({ type: "FOLDER", path: collectionName });
    this.closeCollectionPopup();
  };
  closeFileUploadPopup = () => {
    this.setState({ fileUploadPopupOpen: false });
  };
  handleSelectClick = (file) => {
    this.props.history.push(`/files/${file.path}`);
    this.setState({ selectedFile: file });
  };

  addFolder = (file) => {
    this.setState({ collectionPopupOpen: true });
  };
  deleteFolder = (file) => {
    this.setState({ selectedFile: undefined }, () =>
      this.props.deleteFiles({ path: file.path, collection: file.collection })
    );
  };
  updateFile = (fileId, updates, path) => {
    this.setState({ selectedFile: { ...this.state.selectedFile, ...updates } });
    this.props.updateFile(fileId, updates, path);
  };
  updateFiles = (collectionId, updates) => {
    this.props.updateFiles(collectionId, updates);
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Conversations</title>
        </Helmet>
        <div className="files-content">
          <FileUploadPopup
            open={this.state.fileUploadPopupOpen}
            selectedFile={this.state.selectedFile}
            uploadFile={this.props.uploadMulti}
            onClose={this.closeFileUploadPopup}
          />
          <AddCollectionPopup
            path={this.state.selectedFile ? this.state.selectedFile.path : null}
            open={this.state.collectionPopupOpen}
            handleSubmit={this.handleCollectionSubmit}
            onClose={this.closeCollectionPopup}
          />
          <Grid container style={{ height: "calc(100vh - 60px)" }}>
            <Grid item xs={12} sm={3} md={2}>
              <div className="file-browser-container">
                <FileBrowser
                  className="file-browser"
                  addFolder={this.addFolder}
                  deleteFolder={this.deleteFolder}
                  updateFiles={this.updateFiles}
                  files={this.props.files}
                  handleSelectClick={this.handleSelectClick}
                  openNewCollectionPopup={this.openNewCollectionPopup}
                  selectedFile={
                    this.state.selectedFile
                      ? this.state.selectedFile.path
                      : null
                  }
                  urlPath={this.props.match.params.path}
                  fileUploadStatus={this.props.fileUploadStatus}
                  loading={this.props.loading}
                />
                <div>
                  {this.props.filesUsage && (
                    <div className="files-usage-container">
                      <p className="files-usage-text">
                        <b>File Usage</b>: {this.props.filesUsage?.usage} /{" "}
                        {this.props.filesUsage?.usageQuota}
                      </p>
                      <p
                        className="files-usage-subtext"
                        onClick={() => this.props.history.push("plans")}
                      >
                        Click here to check out our premium plans.
                      </p>

                      <LinearProgress
                        variant="determinate"
                        value={Math.min(
                          (100 * this.props.filesUsage.usage) /
                            this.props.filesUsage.usageQuota,
                          100
                        )}
                        color={
                          this.props.filesUsage.usage >=
                          this.props.filesUsage.usageQuota
                            ? "secondary"
                            : "primary"
                        }
                      />
                    </div>
                  )}
                  <div
                    className="add-new-file-browser"
                    onClick={this.openNewCollectionPopup}
                    style={{ cursor: "pointer" }}
                  >
                    <AddCollectionButton type="CONTAINER" />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className="collection-title-container">
                        <span>Add New</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={9} md={10} className="file-viewer-grid">
              <FileViewer
                key={
                  this.state.selectedFile
                    ? this.state.selectedFile.name
                    : "EMPTY-VIEWER"
                }
                file={this.state.selectedFile}
                allFiles={this.props.files}
                updateFile={this.updateFile}
                handleSelectClick={this.handleSelectClick}
                fileUploadProgress={this.props.fileUploadProgress}
                fileUploadStatus={this.props.fileUploadStatus}
                updateSaving={this.props.updateSaving}
                addFolder={this.addFolder}
                loading={this.props.loading}
              />
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Files));
