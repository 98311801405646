import React, { useState, useEffect } from 'react';

const DeprecationWarning = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <div id="deprecation-warning">
      <p>ATTENTION: Phonic is being discontinued. You may continue to use the platform to collect survey responses until June 30, 2025. Data access and exports will be available until September 30, 2025. On October 1, 2025 all accounts will be disabled. Have questions? Our team is here to help you navigate the transition!</p>
      <button
          onClick={() => setIsVisible(false)}
          className="absolute top-2 right-4 text-white hover:opacity-80 focus:outline-none font-medium text-xl leading-none"
          aria-label="Close warning"
        >
          ✕
        </button>
    </div>
  );
}

export default DeprecationWarning;