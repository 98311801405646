import React from "react";
import { withRouter } from "react-router-dom";
import { ScaleLoader } from "react-spinners";

class SignInPrompt extends React.Component {
  state = { promptSignIn: false };
  componentDidMount() {
    this.showPromptTimeout = setTimeout(() => {
      this.setState({ promptSignIn: true });
    }, 6000);
  }
  componentWillUnmount() {
    clearTimeout(this.showPromptTimeout);
  }

  render() {
    if (
      this.state.promptSignIn &&
      this.props.isSignedIn &&
      this.props.authToken &&
      !this.props.user
    )
      this.props.history.push("/create-account");

    return (
      <div className="prompt-container">
        {!this.state.promptSignIn ? (
          <ScaleLoader size={"30px"} color={"#62cb88"} loading={true} />
        ) : (
          <div>
            <h3>It looks like you're not signed in.</h3>
            <p>
              <a href="/signin">Click here to sign in.</a>
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(SignInPrompt);
