import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1, 0, 1),
  },
}));

export default function SignInForm(props) {
  const classes = useStyles();

  return (
    <Grid id="auth-screen" className="flex-in-center-fullscreen-90" container>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          {props.organization && props.organization.logoUri && (
            <img
              src={props.organization.logoUri}
              className="login-logo"
              alt="Login logo"
            />
          )}
          {!props.organizationId && (
            <img
              src={require("../assets/img/Phonic_Logo_4x.png")}
              className="login-logo"
              alt="Login logo"
            />
          )}

          <h2>Sign In</h2>
          <form className={classes.form} onSubmit={props.signIn}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={props.formChange.bind(this, "email")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={props.formChange.bind(this, "password")}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className="login-button"
                >
                  Sign In
                </Button>
              </Grid>
            </Grid>

            <p
              variant="body2"
              onClick={props.formChange.bind(this, "forgot-password")}
              style={{ marginTop: 10 }}
              className="clickable small"
            >
              Forgot password?
            </p>
          </form>
          <div id="section-header">
            <div style={{ marginTop: -10, color: "gray" }}>─── Or ───</div>
          </div>
          <Button
            fullWidth
            variant="contained"
            className="google-signin-button"
            onClick={props.googleSignIn}
          >
            <img
              id="google-icon"
              src={require("../assets/img/icons8-google.svg")}
              alt="google-logo"
            />
            &nbsp; Sign in With Google
          </Button>
        </div>
      </Container>
    </Grid>
  );
}
