import React from "react";
import _ from "lodash";
import { faPlusSquare, faMinusSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select, MenuItem, TextField } from "@material-ui/core";

import { removeItemFromArray } from "utils/utils";
import { normalizedLikertInputsConfig, LikertInputTypes } from "utils/questionConfig/likert"
import PropCheckbox from "../../../components/SurveyCreation/PropCheckbox";

export default class LikertConfig extends React.Component {

  static getDerivedStateFromProps(nextProps, prevState) {
    const options = nextProps.getQInfoIfMissingState('options');
    const optionsMatrix = nextProps.getQInfoIfMissingState('optionsMatrix')

    let configSelectionIdx = (prevState ? prevState.configSelectionIdx : 0);
    if(prevState && prevState.configData.inputType !== optionsMatrix.inputType) {
      configSelectionIdx = 0;
    }

    return {
      configSelectionIdx: configSelectionIdx,
      options: options, optionsMatrix: optionsMatrix,
      configData: {
        inputType: optionsMatrix.inputType || 'RADIO',
        inputs: normalizedLikertInputsConfig(optionsMatrix, options),
        labels: optionsMatrix.labels
      }
    }
  }

  // Helper method to ensure values are never empty and are unique
  ensureNonEmptyValues(customItems) {
    const usedValues = new Set();
    
    return customItems.map((item, index) => {
      let value = item.value;
      
      // If value is empty, set a default value based on index
      if (value === "" || value === null || value === undefined) {
        value = index + 1;
      } else {
        // Convert to number if it's a string
        value = isNaN(Number(value)) ? index + 1 : Number(value);
      }
      
      // Ensure value is unique
      let uniqueValue = value;
      let counter = 0;
      
      while (usedValues.has(uniqueValue)) {
        counter++;
        uniqueValue = value + counter;
      }
      
      usedValues.add(uniqueValue);
      return { ...item, value: uniqueValue };
    });
  }

  render() {
    const { configSelectionIdx, configData } = this.state;
    
    return(
      <>
        {
          LikertInputTypes[configData.inputType].optionValuesConfig.indexOf('custom') !== -1 && (
            <div>
              <PropCheckbox
                label={{ text: "Enable Custom Values", placement: "right" }}
                update={(e) => {
                  const cData = { ...this.state.configData, inputs: _.cloneDeep(configData.inputs) }
                  cData.inputs[configSelectionIdx].isCustom = e.target.checked;
                  
                  // Add default values when enabling custom mode
                  if (e.target.checked && cData.inputs[configSelectionIdx].custom.length === 0) {
                    // Add first option with default values
                    cData.inputs[configSelectionIdx].custom.push({ 
                      label: "Option 1", 
                      value: 1
                    });
                    
                    // Optional: Add a second option to demonstrate the feature better
                    cData.inputs[configSelectionIdx].custom.push({ 
                      label: "Option 2", 
                      value: 2
                    });
                  }
                  
                  // Ensure all existing custom options have non-empty values
                  cData.inputs[configSelectionIdx].custom = this.ensureNonEmptyValues(
                    cData.inputs[configSelectionIdx].custom
                  );
                  
                  this.props.updateQInfo({ optionsMatrix: { ...cData } }, true)
                }}
                value={configData.inputs[configSelectionIdx].isCustom}
                disabled={false}
              />
            </div>
          )
        }
        
        {
          LikertInputTypes[configData.inputType].optionalLabels && (
            <>
              <PropCheckbox
                label={{ text: "Display Slider Labels", placement: "right" }}
                update={(e) => {
                  const position = (e.target.checked ? 'left' : 'none');
                  const cData = { ...this.state.configData, labels: { position } }
                  this.props.updateQInfo({ optionsMatrix: { ...cData } }, true)
                }}
                value={configData.labels.position === 'left'}
                disabled={false}
              />
            </>
          )
        }
        <br />
        <Select
          fullWidth
          variant="outlined"
          value={configData.inputType}
          onChange={(e) => {
            const cData = { ...configData, inputType: e.target.value }
            this.props.updateQInfo({ optionsMatrix: { ...cData } }, true);
          }}
        >
          {
            Object.keys(LikertInputTypes).map((type, idx) => (
              <MenuItem value={type} key={idx}>{ Object.values(LikertInputTypes)[idx].description }</MenuItem>
            ))
          }
        </Select><br/><br/>
        {
          LikertInputTypes[configData.inputType].configByOption && (
            <>
              <Select
                fullWidth
                variant="outlined"
                value={configSelectionIdx}
                onChange={(e) => this.setState({ ...this.state, configSelectionIdx: e.target.value })}
              >
                {
                  this.state.options.map((option, idx) => (
                    <MenuItem value={idx} key={idx}>{ `Option ${idx + 1}. ${option.text}` }  </MenuItem>
                  ))
                }
              </Select><br/><br/>
            </>
          )
        }
        {
          configData.inputs[configSelectionIdx].isCustom && 
          LikertInputTypes[configData.inputType].optionValuesConfig.indexOf('custom') !== -1 && (
          configData.inputs[configSelectionIdx].custom.map((option, index) => {
              return (
                <div
                  className="translation-line"
                  style={{ display: "flex", alignItems: "center" }}
                  key={index}
                >
                  <TextField
                    value={option.label}
                    variant="outlined"
                    margin="dense"
                    className="translation-flex custom-likert-label-input"
                    placeholder="Value"
                    onChange={(e) => {
                      const cData = { ...configData, inputs: _.cloneDeep(configData.inputs) }
                      cData.inputs[configSelectionIdx]['custom'][index]['label'] = e.target.value;
                      this.props.updateQInfo({ optionsMatrix: { ...cData } }, true);
                    }}
                  />
                  <div className="selection-options-updown add-translation-lang">
                    <FontAwesomeIcon
                      icon={faPlusSquare}
                      className="add-response"
                      onClick={() => {
                        const cData = { ...configData, inputs: _.cloneDeep(configData.inputs) };
                        // Add a default value when adding a new custom option
                        const newIndex = cData.inputs[configSelectionIdx].custom.length + 1;
                        
                        // Generate a unique value that doesn't exist yet
                        let newValue = newIndex;
                        const existingValues = new Set(
                          cData.inputs[configSelectionIdx].custom.map(item => Number(item.value))
                        );
                        
                        // If the value already exists, find a unique one
                        if (existingValues.has(newValue)) {
                          let counter = 0;
                          while (existingValues.has(newValue)) {
                            counter++;
                            newValue = newIndex + counter;
                          }
                        }
                        
                        cData.inputs[configSelectionIdx].custom.push({ 
                          label: `Option ${newIndex}`, 
                          value: newValue
                        });
                        
                        this.props.updateQInfo({ optionsMatrix: { ...cData } }, true);
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faMinusSquare}
                      className="add-response"
                      onClick={() => {
                        if (configData.inputs[configSelectionIdx].custom.length <= 1) { return }
                        const cData = { ...configData, inputs: _.cloneDeep(configData.inputs) }
                        cData.inputs[configSelectionIdx]['custom'] = removeItemFromArray(
                          configData.inputs[configSelectionIdx].custom, 
                          index
                        )
                        this.props.updateQInfo({ optionsMatrix: { ...cData } }, true);
                      }}
                    />
                  </div>
                  <TextField
                      type="number"
                      value={option.value === "" ? index + 1 : option.value}
                      variant="outlined"
                      margin="dense"
                      className="translation-flex recode-likert-label-input"
                      placeholder="Recode #"
                      onChange={(e) => {
                        const cData = { ...configData, inputs: _.cloneDeep(configData.inputs) }
                        // Ensure value is never empty and is a number
                        let newValue = e.target.value === "" ? index + 1 : Number(e.target.value);
                        
                        // If not a valid number, use index+1
                        if (isNaN(newValue)) {
                          newValue = index + 1;
                        }
                        
                        // Check if this value would be a duplicate
                        const isDuplicate = cData.inputs[configSelectionIdx].custom.some(
                          (item, idx) => idx !== index && Number(item.value) === newValue
                        );
                        
                        // If it's a duplicate, modify it to make it unique
                        let uniqueValue = newValue;
                        if (isDuplicate) {
                          let counter = 0;
                          while (cData.inputs[configSelectionIdx].custom.some(
                            (item, idx) => idx !== index && Number(item.value) === uniqueValue
                          )) {
                            counter++;
                            uniqueValue = newValue + counter;
                          }
                        }
                        
                        cData.inputs[configSelectionIdx]['custom'][index]['value'] = uniqueValue;
                        this.props.updateQInfo({ optionsMatrix: { ...cData } }, true);
                      }}
                  />
                </div>
              )
            })
          )
        }
        {
          LikertInputTypes[configData.inputType].optionValuesConfig.indexOf('bounds') !== -1 && (
            <>
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                label="Lower Bound"
                type='number'
                value={configData.inputs[configSelectionIdx].bounds.lower.value}
                onChange={(e) => {
                  const cData = { ...this.state.configData, inputs: _.cloneDeep(configData.inputs)};
                  cData.inputs[configSelectionIdx]['bounds']['lower']['value'] = Number(e.target.value);
                  this.props.updateQInfo({ optionsMatrix: { ...cData } }, true);
                }}
                style={{ marginBottom: 16, marginTop: 0 }}
              />
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                label="Lower Bound Label"
                value={configData.inputs[configSelectionIdx].bounds.lower.label}
                onChange={(e) => {
                  const cData = { ...this.state.configData, inputs: _.cloneDeep(configData.inputs)};
                  cData.inputs[configSelectionIdx]['bounds']['lower']['label'] = e.target.value;
                  this.props.updateQInfo({ optionsMatrix: { ...cData } }, true);
                }}
                style={{ marginBottom: 16, marginTop: 0 }}
              />
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                label="Upper Bound"
                type='number'
                value={configData.inputs[configSelectionIdx].bounds.upper.value}
                onChange={(e) => {
                  const cData = { ...this.state.configData, inputs: _.cloneDeep(configData.inputs)};
                  cData.inputs[configSelectionIdx]['bounds']['upper']['value'] = Number(e.target.value);
                  this.props.updateQInfo({ optionsMatrix: { ...cData } }, true);
                }}
                style={{ marginBottom: 16, marginTop: 0 }}
              />
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                label="Upper Bound Label"
                value={configData.inputs[configSelectionIdx].bounds.upper.label}
                onChange={(e) => {
                  const cData = { ...this.state.configData, inputs: _.cloneDeep(configData.inputs)};
                  cData.inputs[configSelectionIdx]['bounds']['upper']['label'] = e.target.value;
                  this.props.updateQInfo({ optionsMatrix: { ...cData } }, true);
                }}
                style={{ marginBottom: 16, marginTop: 0 }}
              />
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                label="Interval"
                type='number'
                value={configData.inputs[configSelectionIdx].bounds.interval}
                onChange={(e) => {
                  const cData = { ...this.state.configData, inputs: _.cloneDeep(configData.inputs)};
                  cData.inputs[configSelectionIdx]['bounds']['interval'] = Number(e.target.value);
                  this.props.updateQInfo({ optionsMatrix: { ...cData } }, true);
                }}
                style={{ marginBottom: 16, marginTop: 0 }}
              />
            </>
          )
        }
      </>
    );
  }
}