import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItem, Select, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { Component } from "react";
import { removeItemFromArray, updateItemInArray } from "utils/utils";
import { supportedLanguages } from "utils/data";

class LogicItem extends Component {
  constructor(props) {
    super(props);
    // Initialize with default values to prevent undefined errors
    this.state = {
      logic: {
        conditionSubject: this.props.logic?.conditionSubject || "",
        conditionAssign: this.props.logic?.conditionAssign || "",
        keyword: this.props.logic?.keyword ?? "",
        operand: this.props.logic?.operand ?? ""
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.logic !== prevProps.logic)
      this.setState({
        logic: {
          conditionSubject: this.props.logic?.conditionSubject || "",
          conditionAssign: this.props.logic?.conditionAssign || "",
          keyword: this.props.logic?.keyword ?? "",
          operand: this.props.logic?.operand ?? ""
        },
      });
  }

  updateLogicLocal = (logic) => {
    if (this.state.updateTimeout) {
      clearTimeout(this.state.updateTimeout);
    }
    this.setState({
      logic: {
        ...this.state.logic,
        ...logic,
      },
      updateTimeout: setTimeout(() => {
        this.props.updateLogic && this.props.updateLogic(this.state.logic);
      }, 250),
    });
  };

  render() {
    // Add error handling for required props
    if (!this.props.questions || this.props.questionIdx === undefined || this.props.questionIdx < 0) {
      return <Alert severity="error">Required question data is missing</Alert>;
    }

    // Safely access nested properties
    const currentQuestion = this.props.questions[this.props.questionIdx];
    if (!currentQuestion) {
      return <Alert severity="error">Question not found at index {this.props.questionIdx}</Alert>;
    }

    const questionType = currentQuestion.type || "";
    const options = currentQuestion.options || [];
    
    // Safely access logic properties with defaults
    const logicProps = this.props.logic || {};
    const subject = logicProps.subject || "ANSWER";
    const action = logicProps.action || "SKIP TO";
    const operator = logicProps.operator || "IS";
    const likertOption = logicProps.likertOption || "";
    const execute = logicProps.execute || "AFTER";

    return (
      <div id="logic-container">
        <div className="remove-button">
          <FontAwesomeIcon 
            icon={faTimes} 
            onClick={this.props.deleteLogic || (() => {})} 
          />
        </div>

        <div className="logic-line">
          <div className="logic-text">If</div>
          <Select
            value={subject}
            variant="outlined"
            margin="dense"
            className="logic-long"
            onChange={(e) =>
              this.props.updateLogic && this.props.updateLogic({ subject: e.target.value })
            }
          >
            <MenuItem value="CONDITION">Condition</MenuItem>
            <MenuItem value="ANSWER">Answer</MenuItem>
          </Select>
          {subject === 'CONDITION' && (
            <>
              <div className="logic-text">:</div>
              <TextField
                variant="outlined"
                margin="dense"
                className="logic-long"
                value={this.state.logic.conditionSubject}
                placeholder="Condition"
                onChange={(e) =>
                  this.updateLogicLocal({ conditionSubject: e.target.value })
                }
              />
            </>
          )}
          <Select
            value={operator}
            variant="outlined"
            margin="dense"
            className="logic-short"
            onChange={(e) =>
              this.props.updateLogic && this.props.updateLogic({ operator: e.target.value })
            }
          >
            <MenuItem value="IS">Is</MenuItem>
            <MenuItem value="IS NOT">Is not</MenuItem>
            <MenuItem value="CONTAINS">Contains</MenuItem>
            <MenuItem value="DOES NOT CONTAIN">Does not contain</MenuItem>
          </Select>
          <TextField
            variant="outlined"
            margin="dense"
            className="logic-long"
            value={this.state.logic.keyword}
            placeholder="Keyword (s)"
            onChange={(e) => this.updateLogicLocal({ keyword: e.target.value })}
          />
        </div>

        {questionType === 'LIKERT' && subject === 'ANSWER' && (
          <div className="logic-line">
            <div className="logic-text">For</div>
            <Select
              value={likertOption}
              variant="outlined"
              margin="dense"
              className="logic-long"
              onChange={(e) =>
                this.props.updateLogic && this.props.updateLogic({ likertOption: e.target.value })
              }
            >
              {options.map(
                (option, idx) => {
                  return (
                    <MenuItem key={`Option-${idx}`} value={option?.text || ""}>
                      Option {idx + 1} - {option?.text || ""}
                    </MenuItem>
                  );
                }
              )}
            </Select>
          </div>
        )}
        
        <div className="logic-line">
          <div className="logic-text">Then</div>
          <Select
            value={action}
            variant="outlined"
            margin="dense"
            className="logic-long"
            onChange={(e) => 
              this.props.updateLogic && this.props.updateLogic({ action: e.target.value })
            }
          >
            <MenuItem value="SKIP TO">Skip to</MenuItem>
            <MenuItem value="ASSIGN CONDITION">Set condition</MenuItem>
            <MenuItem value="SET LANGUAGE">Set language to </MenuItem>
          </Select>
          {action === 'SKIP TO' && (
            <Select
              value={this.props.logic.operand}
              variant="outlined"
              margin="dense"
              className="logic-long"
              onChange={(e) =>
                this.props.updateLogic && this.props.updateLogic({ operand: e.target.value })
              }
            >
              <MenuItem value={0}>Next Question</MenuItem>
              {(this.props.questions || []).map((q, idx) => {
                if (!q) return null;
                return (
                  <MenuItem key={`LOGIC-SKIP-${idx}`} value={q.questionId || idx}>
                    Question {idx + 1}
                  </MenuItem>
                );
              })}
              <MenuItem value={-1}>End Survey</MenuItem>
              <MenuItem value={-2}>Screen Out</MenuItem>
            </Select>
          )}
          {action === 'ASSIGN CONDITION' && (
            <>
              <TextField
                variant="outlined"
                margin="dense"
                className="logic-long"
                value={this.state.logic.operand}
                placeholder="Condition"
                onChange={(e) =>
                  this.updateLogicLocal({ operand: e.target.value })
                }
              />
              <div className="logic-text">=</div>
              <TextField
                variant="outlined"
                margin="dense"
                className="logic-long"
                value={this.state.logic.conditionAssign}
                placeholder="Value"
                onChange={(e) =>
                  this.updateLogicLocal({ conditionAssign: e.target.value })
                }
              />
            </>
          )}
          {action === 'SET LANGUAGE' && (
            <Select
              value={this.state.logic.conditionAssign}
              variant="outlined"
              margin="dense"
              className="logic-long"
              onChange={(e) => {
                this.updateLogicLocal({
                  operand: 'lang',
                  conditionAssign: e.target.value,
                });
              }}
            >
              {(supportedLanguages || []).map((l, idx) => {
                if (!l) return null;
                return (
                  <MenuItem key={`LANGUAGE-LOGIC-${idx}`} value={l.code || ""}>
                    {l.text || ""}
                  </MenuItem>
                );
              })}
              <MenuItem value={-1}>End Survey</MenuItem>
              <MenuItem value={-2}>Screen Out</MenuItem>
            </Select>
          )}
        </div>
        {subject === 'CONDITION' && (
          <div className="logic-line">
            <div className="logic-text alternate">Run</div>
            <Select
              value={execute}
              variant="outlined"
              margin="dense"
              className="logic-long"
              onChange={(e) =>
                this.props.updateLogic && this.props.updateLogic({ execute: e.target.value })
              }
            >
              <MenuItem value="BEFORE">Before Question</MenuItem>
              <MenuItem value="AFTER">After Question</MenuItem>
            </Select>
          </div>
        )}
        {action === 'ASSIGN CONDITION' &&
          this.state.logic.operand === 'lang' && (
            <div className="logic-line">
              <Alert severity="info" className="survey-info-alert logic-alert">
                The <b>lang</b> condition will set the survey and transcription
                language.
              </Alert>
            </div>
          )}
      </div>
    );
  }
}

class Logic extends Component {
  updateLogic = (idx, logic) => {
    if (!this.props.changeLogicInfo || !this.props.logic) return;
    
    this.props.changeLogicInfo(
      updateItemInArray(this.props.logic, idx, {
        ...this.props.logic[idx],
        ...logic,
      })
    );
  };
  
  deleteLogic = (idx) => {
    if (!this.props.changeLogicInfo || !this.props.logic) return;
    
    this.props.changeLogicInfo(removeItemFromArray(this.props.logic, idx));
  };
  
  render() {
    // Handle case where logic array is missing or not an array
    if (!this.props.logic || !Array.isArray(this.props.logic)) {
      return <Alert severity="warning">No logic conditions available</Alert>;
    }
    
    // Ensure questions is available
    if (!this.props.questions || !Array.isArray(this.props.questions)) {
      return <Alert severity="error">Question data is missing</Alert>;
    }
    
    // Ensure questionIdx is valid
    if (this.props.questionIdx === undefined || this.props.questionIdx < 0 || 
        this.props.questionIdx >= this.props.questions.length) {
      return <Alert severity="error">Invalid question index</Alert>;
    }
    
    return (
      <>
        {this.props.logic.map((l, idx) => {
          if (!l) return null;
          return (
            <LogicItem
              key={`LOGIC-${idx}`}
              updateLogic={(logic) => this.updateLogic(idx, logic)}
              deleteLogic={() => this.deleteLogic(idx)}
              logic={l}
              questionIdx={this.props.questionIdx}
              questions={this.props.questions}
            />
          );
        })}
      </>
    );
  }
}

export default Logic;